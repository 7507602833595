import * as React from "react"

//import Logo from '../images/svg/logo-full.inline.svg';

import SEO from "../components/seo"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <header className="header">
      {/* <img className="header__logo" src="../images/Logo StudioFAS Full.svg" alt="" /> */}
      <h1>Coldstream Management</h1>
    </header>
  </Layout>
);

export default IndexPage
